import places from '../places.js';

const isDarkMode = () => window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

const buildSummary = overlayNode => {
    const summaryNode = overlayNode.querySelector('.summary');
    const main = summaryNode.querySelector('p');

    main.innerHTML = `Visited ${Object.keys(places).length} from 195<sup>*</sup> countries.`;
}

export default () => {
    const overlay = document.querySelector('.overlay');

    buildSummary(overlay);

    overlay.style.display = "block";

    Promise.all([
            import ('svgmap'),
            import ('svgmap/dist/svgMap.min.css'),
            import ('../css/svgMapOverwrites.less'),
        ])
        .then(([svgMap]) => {
            new svgMap({
                targetElementID: 'map',
                colorNoData: isDarkMode() ? '#fff' : '#000',
                colorMax: '#888888',
                data: {
                    data: {
                        visited: {
                            name: 'Have I visited this country?',
                            thresholdMin: 0,
                            thresholdMax: 1,
                        },
                        when: {
                            name: 'When was that happened?',
                            thresholdMin: 1994,
                            thresholdMax: new Date().getFullYear(),
                        },
                        what: {
                            name: 'What doing there?'
                        },
                    },
                    applyData: 'visited',
                    values: places,
                }
            });
        })

    return () => overlay.style.display = "none";
}