class KeyManager {
    stored = [];

    constructor(phrase, action) {
        this.phrase = phrase.getArrayOfCharCodes();

        this.action = typeof action === 'function' ? action : this.action;
    }

    compare() {
        return this.phrase.toString() === this.stored.toString();
    }

    check() {
        this.stored.length > this.phrase.length - 1 && this.stored.pop();
    }

    push(letter) {
        this.stored.unshift(letter);
    }

    action() {
        console.warn("No action implemented");
    }
}

export default KeyManager;