import KeyManager from "./KeyManager";
import InfoModule from "./InfoModule";
import MapModule from "./MapModule";

String.prototype.getArrayOfCharCodes = function () {
    return this.split('').reverse().map(c => c.charCodeAt(0));
}

const info = new KeyManager("info", InfoModule);
const map = new KeyManager("map", MapModule);

document.addEventListener("keypress", event => {
    const keyCode = event.keyCode || event.charCode;

    [info, map].forEach(manager => {
        manager.check();

        manager.push(keyCode);

        if (manager.compare()) {
            const disableCb = manager.action();
            document.addEventListener("keydown", disableCb, { once: true });
        }
    });
});