export default {
    PL: {
        visited: 1,
        when: 1994,
        what: 'Born here.',
    },
    DE: {
        visited: 1,
        when: [2017, 2017, 2018], // to be verified
        what: 'Visiting Berlin with Ola as a part of 2k17 MX-5 Euro trip and then Dota 2 Major in Hanover, also with Ola',
    },
    CZ: {
        visited: 1,
        when: 2017,
        what: 'Visiting Praga with Ola as a part of 2k17 MX-5 Euro trip',
    },
    AT: {
        visited: 1,
        when: 2017,
        what: 'Visiting Vienna with Ola as a part of 2k17 MX-5 Euro trip',
    },
    HU: {
        visited: 1,
        when: [2014, 2015, 2017],
        what: 'Holidays with parents, year later Formula 1 on Hungaroring, and visiting Budapest with Ola as a part of 2k17 MX-5 Euro trip',
    },
    IT: {
        visited: 1,
        when: [2017, 2019, 2020],
        what: 'Trip with Sidor\'s and Dawid, snowboarding two years later, and yet a year later snowboarding with Ola',
    },
    SK: {
        visited: 1,
        when: 2015,
        what: "Skis"
    },
    HR: {
        visited: 1,
        when: [2014, 2017],
        what: 'Holidays with parents and then visitng Dubrovnik with Ola as a part of 2k17 MX-5 Euro trip',
    },
    ME: {
        visited: 1,
        when: [2015, 2017],
        what: 'Holidays, fun fact - I met Mirela back then, and then holidays and sightseeing Kotor with Ola as a part of 2k17 MX-5 Euro trip.',
    },
    AL: {
        visited: 1,
        when: 2017,
        what: 'Visiting and sightseeing Tirana with Ola as a part of 2k17 MX-5 Euro trip.',
    },
    BA: {
        visited: 1,
        when: 2017,
        what: 'Visiting and sightseeing Sarajevo with Ola as a part of 2k17 MX-5 Euro trip.',
    },
    DK: {
        visited: 1,
        when: [2012, 2016]  ,
        what: 'Project from secondary school - fun fact - I met Aleksandra there, the second time was a trip with Milosz and Michal (Copenhaga, Bornholm, Christianso)'
    },
    SE: {
        visited: 1,
        when: 2016,
        what: 'Baltic offshore with Milosz and Michal'
    },
    SG: {
        visited: 1,
        when: 2018,
        what: 'Singapure\s airport as a step over of the travel to Sydney',
    },
    AU: {
        visited: 1,
        when: 2018,
        what: 'First company trip to Sydney,'
    },
    QA: {
        visited: 1,
        when: 2018,
        what: 'Doha\s airport as a step over of the travel from Sydney to Poland',
    },
    NL: {
        visited: 1,
        when: 2019,
        what: 'Company trip with Ignat. Watching Ajax in the semi-finals of Champions League.',
    },
    FR: {
        visited: 1,
        when: 2019,
        what: 'Holidays in Nice (La côte d\'azur) with Ola',
    },
    MC: {
        visited: 1,
        when: 2019,
        what: 'Holidays in Nice (La côte d\'azur) with Ola',
    },
    AX: {
        visited: 1,
        when: 2020,
        what: 'Second sailing on the Balitc Sea with Milosz and Michal',
    },
    FI: {
        visited: 1,
        when: 2020,
        what: 'Visited and stayed on Alands as a part of second sailing on the Balitc Sea with Milosz and Michal',
    },
    EE: {
        visited: 1,
        when: 2020,
        what: 'Visited Kuressaare as a part of second sailing on the Baltic Sera with Milosz and Michal',
    },
    ES: {
        visited: 1,
        when: [2021, 2022],
        what: 'Visited and stayed in Conil de la Frontera for 3 months. City break in Valencia and Madrid (watching Real Madrid vs Real Betis on Santiago Bernabeu)',
    }
}